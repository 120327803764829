var audio = new Audio("/sfx/HUMAN_VOCAL_Male_Curse_Evil_11.mp3");
var girlSound = new Audio("/sfx/eh nani.mp3");
var sempai = new Audio("/sfx/sempai.mp3");
// var dancesfx = new Audio("/sfx/Caramella-Girls-Caramelldansen-O.mp3");
// dancesfx.volume = 0.035; // Reduces volume to 50%

const baseUrl = "https://pumpsenpai.com/"; // Ensure this matches your deployed URL
// document.getElementById("login-button").addEventListener("click", () => {
//   // document.querySelector(".left-section").style.visibility = "hidden";
//   // document.querySelector(".Rules").style.visibility = "hidden";
//   document.querySelector(".right-section").style.visibility = "visible";
//   document.querySelector(".return-button").style.visibility = "visible";
//   // document.querySelector(".login-button").style.visibility = "hidden";
// });

document.getElementById("return-button").addEventListener("click", () => {
  // document.querySelector(".Rules").style.visibility = "visible  ";
  // document.querySelector(".left-section").style.visibility = "visible";
  document.querySelector(".right-section").style.visibility = "hidden";
  document.querySelector(".return-button").style.visibility = "hidden";
  // document.querySelector(".login-button").style.visibility = "visible";
});
// Check if user is authenticated
async function checkAuthStatus() {
  try {
    const response = await fetch(`${baseUrl}/api/auth-status`, {
      method: "GET",
      credentials: "include",
    });
    const data = await response.json();

    if (data.authenticated) {
      document.getElementById("connectTwitter").style.display = "none";
      document.getElementById("login-group-id").style.display = "block";
      // document.getElementById("login-button").textContent = "Profile";
      window.isAuthenticated = true;
      // displayAuthenticatedUsername();
      // fetchUserWickedPoints();
    } else {
      window.isAuthenticated = false;
    }
  } catch (error) {
    console.error("Error checking authentication status:", error);
  }
}

// Redirect to Twitter authentication if not authenticated
document
  .getElementById("connectTwitter")
  .addEventListener("click", async () => {
    try {
      const response = await fetch(`${baseUrl}/api/auth`);
      const data = await response.json();
      window.location.href = data.url; // Redirects to Twitter login URL
    } catch (error) {
      console.error("Error initiating authentication:", error);
    }
  });
// document
//   .getElementById("connectTwitterModal")
//   .addEventListener("click", async () => {
//     try {
//       const response = await fetch(`${baseUrl}/api/auth`);
//       const data = await response.json();
//       window.location.href = data.url; // Redirects to Twitter login URL
//     } catch (error) {
//       console.error("Error initiating authentication:", error);
//     }
//   });
// async function fetchUserWickedPoints() {
//   try {
//     const response = await fetch("/api/get-wicked-points", {
//       method: "GET",
//       credentials: "include", // Include cookies for authentication
//     });

//     if (!response.ok) {
//       console.error("Error fetching Wicked Points.");
//       return;
//     }

//     const { wicked_points } = await response.json();
//     document.getElementById("bottom-wicked-points").textContent =
//       wicked_points || 0; // Update the Wicked Points display
//   } catch (error) {
//     console.error("Error fetching Wicked Points:", error);
//   }
// }

// Fetch Wicked Points on page load
// document.addEventListener("DOMContentLoaded", fetchUserWickedPoints);
// Show authentication modal if needed and process "Curse" form submission
// document.addEventListener("DOMContentLoaded", function () {
//   const replyButton = document.getElementById("replybutton");
//   const tweetForm = document.getElementById("tweetForm");

//   // Trigger form submission when clicking the custom reply button
//   replyButton.addEventListener("click", function () {
//     tweetForm.dispatchEvent(new Event("submit", { cancelable: true }));
//   });

//   // Handle the form submission
//   tweetForm.addEventListener("submit", async function (event) {
//     event.preventDefault();

//     const tweetUrl = document.getElementById("tweet_url").value.trim();
//     const messageDiv = document.getElementById("message");
//     if (!window.isAuthenticated) {
//       document.getElementById("authModal").style.display = "flex";
//       return;
//     }
//     try {
//       const response = await fetch(`/api/reply`, {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ tweet_url: tweetUrl }),
//         credentials: "include", // Ensure cookies are sent
//       });

//       const result = await response.json();

//       if (response.ok) {
//         messageDiv.textContent = result.message;
//         messageDiv.style.color = "white";
//         audio.play();
//         // Refresh leaderboard after a successful curse reply
//         fetchLeaderboard();
//         fetchUserWickedPoints();
//         // Update the displayed curse limit if it was decremented
//         const curseLimitDisplay = document.getElementById("bottom-curse-limit");
//         let currentLimit = parseInt(curseLimitDisplay.textContent, 10) || 0;
//         curseLimitDisplay.textContent = Math.max(currentLimit - 1, 0);
//       } else {
//         messageDiv.textContent = `Error: ${result.error}`;
//         messageDiv.style.color = "orange";
//       }
//     } catch (error) {
//       messageDiv.textContent = `Error: ${error.message}`;
//       messageDiv.style.color = "orange";
//     }

//     setTimeout(() => {
//       messageDiv.textContent = ""; // Clear the message after a short delay
//     }, 3000);
//   });
// });
// Initialize on page load
window.addEventListener("load", checkAuthStatus);

// Close authentication modal
// document.getElementById("cancelModal").addEventListener("click", () => {
//   document.getElementById("authModal").style.display = "none";
// });

// Fetch and display the leaderboard
// async function fetchLeaderboard() {
//   try {
//     const response = await fetch("/api/leaderboard");
//     if (!response.ok) throw new Error("Failed to fetch leaderboard");

//     const leaderboard = await response.json();

//     // Check if leaderboard is an array
//     if (!Array.isArray(leaderboard)) throw new Error("Invalid response format");

//     const leaderboardContent = document.getElementById("leaderboard-content");
//     leaderboardContent.innerHTML = leaderboard
//       .map(
//         (user, index) => `
//         <div class="leaderboard-row">
//           <span class="rank">${index + 1}</span>
//           <span class="username">${user.username}</span>
//           <span class="count">${user.wicked_points}</span>
//         </div>
//       `
//       )
//       .join("");
//   } catch (error) {
//     console.error("Error displaying leaderboard:", error);
//     document.getElementById(
//       "leaderboard-content"
//     ).innerHTML = `<p>Error loading leaderboard</p>`;
//   }
// }
// Helper function to retrieve a cookie by name
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
}
// After checkAuthStatus is called, add this function to set the username
// async function displayAuthenticatedUsername() {
//   const username = getCookie("username"); // Fetch username from cookies
//   if (username) {
//     document.getElementById("bottom-username-display").innerText = username;
//   } else {
//     console.log("Username cookie not found"); // Debugging log
//   }
// }

// document.addEventListener("DOMContentLoaded", fetchLeaderboard);
// Initialize buttons

document.addEventListener("DOMContentLoaded", function () {
  const danceButton = document.getElementById("danceButton");
  const dressButton = document.getElementById("dressButton");
  const allButton = document.getElementById("allButton");
  dressButton.classList.add("disabled");
  allButton.classList.add("disabled");
  // Initially disable all buttons except the danceButton
  danceButton.disabled = false;
  dressButton.disabled = true;
  allButton.disabled = true;
  const tweetForm = document.getElementById("tweetCheck");
  const messageDiv = document.getElementById("message_tweet_check");
  const curseLimitDisplay = document.getElementById("bottom-curse-limit");
  const checkButton = document.getElementById("check-button");
  const timeoutDuration = 0 * 1000; // 1 minute in milliseconds
  const localStorageKey = "tweetCheckTimeout";

  // Add event listener to the tweet check form
  tweetForm.addEventListener("submit", async function (event) {
    event.preventDefault();

    // If cooldown is active, prevent further actions
    if (checkButton.disabled) {
      displayMessage("Please wait for the cooldown to finish.", "white");
      return;
    }

    const tweetUrl = validateTweetUrl();
    if (!tweetUrl) return;
    const tweetUsername = extractUsernameFromTweetUrl(tweetUrl);
    const cookieUsername = getCookie("username");

    if (!cookieUsername) {
      displayMessage("No username found in cookies. Please log in.", "red");
      return;
    }
    if (tweetUsername !== cookieUsername) {
      displayMessage(
        "Username mismatch. You are not the owner of this tweet.",
        "red"
      );
      return;
    }

    // If the usernames match
    // displayMessage("Username matches! Action triggered.", "green");
    triggerNextAction();
    // try {
    //   // Make the API call to validate the tweet
    //   const response = await fetch("/api/validate-tweet-and-grant", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ tweetUrl }),
    //   });

    //   const data = await response.json();

    //   if (response.ok) {
    //     // If the tweet is valid, handle the success message
    //     handleTweetActions(data.action); // Use action from the API response
    //     displayMessage(data.message, "white");

    //     // Activate the cooldown
    //     activateCooldown();
    //   }
    // } catch (error) {
    //   console.error("Error validating tweet:", error);

    //   if (tweetUsername === cookieUsername) {
    //     // Trigger alternative logic if usernames match
    //     handleAlternativeLogic(tweetUsername);
    //   } else {
    //     displayMessage(
    //       "Failed to validate tweet or usernames do not match.",
    //       "red"
    //     );
    //   }
    // }
  });
  function extractUsernameFromTweetUrl(tweetUrl) {
    const match = tweetUrl.match(
      /^https?:\/\/x\.com\/([A-Za-z0-9_]+)\/status\/\d+$/
    );
    return match ? match[1] : null; // Extract the username if the URL is valid
  }
  function handleAlternativeLogic(username) {
    console.log(`Alternative logic triggered for username: ${username}`);
    displayMessage(`Alternative logic executed for ${username}`, "blue");
    // Add any custom actions here
  }

  // Activate cooldown on the Check button
  function activateCooldown() {
    const endTime = Date.now() + timeoutDuration;
    checkButton.disabled = true;
    localStorage.setItem(localStorageKey, endTime);

    const interval = setInterval(() => {
      const timeLeft =
        parseInt(localStorage.getItem(localStorageKey), 10) - Date.now();
      if (timeLeft <= 0) {
        clearInterval(interval);
        checkButton.disabled = false;
        localStorage.removeItem(localStorageKey);
        messageDiv.textContent = "";
      } else {
        const seconds = Math.floor(timeLeft / 1000);
        displayMessage(
          `Please wait ${seconds} seconds before trying again.`,
          "white"
        );
      }
    }, 1000);
  }

  function validateTweetUrl() {
    const tweetUrlInput = document.getElementById("tweet_url_1").value.trim();
    if (!tweetUrlInput) {
      displayMessage("Please enter a Tweet URL.", "white");
      return null;
    }

    let tweetUrl = tweetUrlInput;
    if (!tweetUrl.startsWith("http://") && !tweetUrl.startsWith("https://")) {
      tweetUrl = "https://" + tweetUrl;
    }

    const match = tweetUrl.match(
      /^https?:\/\/x\.com\/([A-Za-z0-9_]+)\/status\/(\d+)$/
    );
    if (!match) {
      displayMessage("Invalid Tweet URL format.", "white");
      return null;
    }

    return tweetUrl;
  }
  let actionStep = 0;

  function triggerNextAction() {
    switch (actionStep) {
      case 0:
        console.log("Triggering Dance action");
        console.log("Dance action triggered.");
        // dancesfx.play();
        danceButton.disabled = false;
        dressButton.disabled = false;
        // tton.disablallBued = false;
        danceButton.classList.add("disabled");
        dressButton.classList.remove("disabled");
        // allButton.classList.remove("disabled");
        break;
      case 1:
        console.log("Triggering Dress action");
        allButton.disabled = false;
        allButton.classList.remove("disabled");
        dressButton.classList.add("disabled");
        dressButton.disabled = true;
        girlSound.play();
        break;
      case 2:
        console.log("Triggering All action");
        allButton.disabled = true;
        allButton.classList.add("disabled");
        sempai.play();
        break;
      default:
    }

    actionStep++; // Increment the step for the next action
  }
  // function handleTweetActions(action) {
  //   switch (action) {
  //     case "Dance":
  //       console.log("Dance action triggered.");
  //       dancesfx.play();
  //       dressButton.disabled = false;
  //       allButton.disabled = false;
  //       dressButton.classList.remove("disabled");
  //       allButton.classList.remove("disabled");

  //       break;
  //     case "Dress":
  //       girlSound.play();
  //       console.log("Dress action triggered.");

  //       break;
  //     case "All":
  //       sempai.play();
  //       console.log("All action triggered.");

  //       break;
  //     default:
  //       console.warn("Unknown action:", action);
  //   }
  // }

  function hideMessageWithTimeout() {
    setTimeout(() => {
      messageDiv.textContent = "";
    }, 60000);
  }

  function displayMessage(message, color) {
    messageDiv.textContent = message;
    messageDiv.style.color = color;
    hideMessageWithTimeout();
  }

  // Handle button clicks to open Twitter intent popups
  function handleButtonClick(buttonId, tweetContent) {
    const button = document.getElementById(buttonId);

    if (button) {
      button.addEventListener("click", function () {
        if (button.disabled) {
          console.warn(`Button "${buttonId}" is disabled.`);
          return;
        }
        const tweetText = encodeURIComponent(tweetContent);
        const twitterUrl = `https://twitter.com/intent/tweet?text=${tweetText}`;

        const width = 600;
        const height = 400;
        const left = (screen.width - width) / 2;
        const top = (screen.height - height) / 2;

        window.open(
          twitterUrl,
          "TweetWindow",
          `width=${width},height=${height},top=${top},left=${left},toolbar=no,menubar=no,scrollbars=no,resizable=no,status=no`
        );
      });
    } else {
      console.warn(`Button with ID "${buttonId}" not found.`);
    }
  }

  // Set up button handlers for tweet intents
  handleButtonClick("danceButton", "Dance for me @pumpsenpai 💃 #pumpsenpai");
  handleButtonClick("dressButton", "Take it off @pumpsenpai 👗 #pumpsenpai");
  handleButtonClick("allButton", "Take it all off @pumpsenpai 👙 #pumpsenpai");

  // Check cooldown status on page load
  const existingTimeout = localStorage.getItem(localStorageKey);
  if (existingTimeout) {
    const timeLeft = parseInt(existingTimeout, 10) - Date.now();
    if (timeLeft > 0) {
      activateCooldown(timeLeft);
    }
  }
});
document.addEventListener("DOMContentLoaded", function () {
  const connectAccountButton = document.getElementById("connectAccount");

  connectAccountButton.addEventListener("click", function () {
    window.open("https://x.com/pumpsenpai", "_blank");
  });
});
let getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

let mainHeart = anime();
let secondHeart = anime();

let path1 = anime.path(".line1");
let path2 = anime.path(".line2");
let path3 = anime.path(".line3");
let path4 = anime.path(".line4");
let path5 = anime.path(".line5");
let path6 = anime.path(".line6");
let path7 = anime.path(".line7");

let floatingHearts = () => {
  $(".heart").show();

  anime({
    targets: ".heart1",
    translateX: path1("x"),
    translateY: path1("y"),
    scale: {
      value: [1, 0],
      delay: getRandomInt(400, 600),
      duration: getRandomInt(1000, 2000),
    },
    easing: "easeInOutCubic",
    duration: 2000,
  });
  anime({
    targets: ".heart2",
    translateX: path2("x"),
    translateY: path2("y"),
    scale: {
      value: [1, 0],
      delay: getRandomInt(400, 600),
      duration: getRandomInt(1000, 2000),
    },
    easing: "easeInOutCubic",
    duration: 2000,
  });
  anime({
    targets: ".heart3",
    translateX: path3("x"),
    translateY: path3("y"),
    scale: {
      value: [1, 0],
      delay: getRandomInt(400, 600),
      duration: getRandomInt(1000, 2000),
    },
    easing: "easeInOutCubic",
    duration: 2000,
  });
  anime({
    targets: ".heart4",
    translateX: path4("x"),
    translateY: path4("y"),
    scale: {
      value: [1, 0],
      delay: getRandomInt(400, 600),
      duration: getRandomInt(1000, 2000),
    },
    easing: "easeInOutCubic",
    duration: 2000,
  });
  anime({
    targets: ".heart5",
    translateX: path5("x"),
    translateY: path5("y"),
    scale: {
      value: [1, 0],
      delay: getRandomInt(400, 600),
      duration: getRandomInt(1000, 2000),
    },
    easing: "easeInOutCubic",
    duration: 2000,
  });
  anime({
    targets: ".heart6",
    translateX: path6("x"),
    translateY: path6("y"),
    scale: {
      value: [1, 0],
      delay: getRandomInt(400, 600),
      duration: getRandomInt(1000, 2000),
    },
    easing: "easeInOutCubic",
    duration: 2000,
  });
  anime({
    targets: ".heart7",
    translateX: path7("x"),
    translateY: path7("y"),
    scale: {
      value: [1, 0],
      delay: getRandomInt(400, 600),
      duration: getRandomInt(1000, 2000),
    },
    easing: "easeInOutCubic",
    duration: 2000,
  });
};

$("body").on("click", ".like", (e) => {
  let $this = $(e.currentTarget);

  if ($this.hasClass("active")) {
    secondHeart.pause();
    mainHeart.pause();
    $this.toggleClass("active");

    mainHeart = anime({
      targets: ".like .main",
      scale: 1,
      duration: 1,
      delay: 0,
    });
    anime({
      targets: ".like .second",
      scale: 0,
      easing: "linear",
      duration: 150,
    });
  } else {
    secondHeart.pause();
    mainHeart.pause();
    $this.toggleClass("active");

    anime({
      targets: ".like .second",
      scale: 0,
      easeing: "linear",
      duration: 0,
      complete: () => {
        secondHeart = anime({ targets: ".like .second", scale: 1 });
        mainHeart = anime({
          targets: ".like .main",
          scale: 0,
          duration: 1,
          delay: 300,
        });
      },
    });

    floatingHearts();
  }
});
