import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import GUI from "lil-gui";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { gsap } from "gsap";
/**
 * Loaders
 */

const loadingBarElement = document.querySelector(".back");
const blackOverlay = document.querySelector(".heart-loading");
let sceneReady = false;
let innerBody = null;
let bikini = null;
let dress = null;
const loadingManager = new THREE.LoadingManager(
  // Loaded
  () => {
    // Wait a little
    window.setTimeout(() => {
      loadingBarElement.style.display = "none";
      blackOverlay.style.display = "none";
    }, 500);

    // window.setTimeout(() => {
    //   blackOverlay.classList.add("ended");
    //   blackOverlay.style.transform = "";
    //   sceneReady = true;
    // }, 2000);
  }

  // Progress
);

const gltfLoader = new GLTFLoader(loadingManager);
const rgbeLoader = new RGBELoader(loadingManager);
const textureLoader = new THREE.TextureLoader(loadingManager);
/**
 * Base
 */

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Update all materials
 */
const updateAllMaterials = () => {
  scene.traverse((child) => {
    if (child.isMesh) {
      child.castShadow = true;
      child.receiveShadow = true;
    }
  });
};

/**
 * Environment map
 */
// Intensity
scene.environmentIntensity = 1.5;
// gui.add(scene, "environmentIntensity").min(0).max(10).step(0.001);

// HDR (RGBE) equirectangular
rgbeLoader.load("/environmentMaps/0/2k.hdr", (environmentMap) => {
  environmentMap.mapping = THREE.EquirectangularReflectionMapping;

  // scene.background = new THREE.Color().setHex(0x1c1c1c);
  scene.environment = environmentMap;
});

/**
 * Directional light
 */
const directionalLight = new THREE.DirectionalLight("#ffffff", 6);
directionalLight.position.set(5, 10, 5);
scene.add(directionalLight);

// Shadows
directionalLight.castShadow = true;

// directionalLight.castShadow = true;
// directionalLight.shadow.camera.far = 15;
// directionalLight.shadow.normalBias = 0.027;
// directionalLight.shadow.bias = -0.004;
directionalLight.shadow.mapSize.set(1024, 1024);

// Target
directionalLight.target.position.set(0, 4, 0);
directionalLight.target.updateWorldMatrix();

// // Helper

// Animation mixer
let mixer;
let action;
const maxTime = 6.25; // Time equivalent to 150 frames at 24 FPS
/**
 * Models
 */
// Shadow Plane

const shadowMat = new THREE.ShadowMaterial({ opacity: 0.25 }); // ShadowMaterial for transparent shadows
let actions = {};
let currentAction;

gltfLoader.load("/models/anime3.glb", (gltf) => {
  gltf.scene.scale.set(2.5, 2.5, 2.5);
  gltf.scene.position.y -= 2.15;
  // gltf.scene.position.x -= 2.15;
  scene.add(gltf.scene);

  innerBody = gltf.scene.getObjectByName("bodyInner");
  bikini = gltf.scene.getObjectByName("bikinni");
  dress = gltf.scene.getObjectByName("dress");
  gltf.scene.getObjectByName("shadow").material = shadowMat;
  innerBody.visible = false;
  bikini.visible = false;

  updateAllMaterials();

  // Create the animation mixer
  mixer = new THREE.AnimationMixer(gltf.scene);
  gltf.animations.forEach((clip) => {
    actions[clip.name] = mixer.clipAction(clip);
  });

  console.log("Available animations:", Object.keys(actions));

  // Play the Idle animation on loop
  playAnimation("idle", THREE.LoopRepeat);
});

function playAnimation(name, loopMode = THREE.LoopOnce) {
  if (currentAction) {
    currentAction.fadeOut(0.5); // Smooth transition
  }

  currentAction = actions[name];
  currentAction.reset();
  currentAction.setLoop(loopMode);
  currentAction.fadeIn(0.5).play();
}

// Helper function to wait for the current animation to finish
function waitForCurrentAnimation(nextName, onComplete) {
  if (currentAction) {
    currentAction.loop = THREE.LoopOnce; // Ensure current animation finishes
    currentAction.clampWhenFinished = true; // Freeze at the last frame

    mixer.addEventListener("finished", () => {
      mixer.removeEventListener("finished"); // Avoid multiple calls
      onComplete();
    });
  }
}

function handleTweetActions(action) {
  console.log("Received action:", action);
  switch (action) {
    case "Dance":
      console.log("Dance action triggered.");
      waitForCurrentAnimation("Transtion", () => {
        playAnimation("Dancing", THREE.LoopRepeat);
      });

      break;
    case "Dress":
      console.log("Dress action triggered.");
      if (bikini) bikini.visible = true;
      if (innerBody) innerBody.visible = true;
      if (dress) dress.visible = false;

      break;
    case "All":
      console.log("All action triggered.");
      if (dress) dress.visible = false;
      if (innerBody) innerBody.visible = true;
      if (bikini) bikini.visible = false;
      break;
    default:
      console.warn("Unknown action:", action);
  }
}
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
}
// Initialize variables for tracking actions
let actionStep = 0; // Tracks the current action step

function extractUsernameFromTweetUrl(tweetUrl) {
  const match = tweetUrl.match(
    /^https?:\/\/x\.com\/([A-Za-z0-9_]+)\/status\/\d+$/
  );
  return match ? match[1] : null; // Extract the username if the URL is valid
}
function validateTweetUrl() {
  const tweetUrlInput = document.getElementById("tweet_url_1").value.trim();
  if (!tweetUrlInput) {
    displayMessage("Please enter a Tweet URL.", "white");
    return null;
  }

  let tweetUrl = tweetUrlInput;
  if (!tweetUrl.startsWith("http://") && !tweetUrl.startsWith("https://")) {
    tweetUrl = "https://" + tweetUrl;
  }

  const match = tweetUrl.match(
    /^https?:\/\/x\.com\/([A-Za-z0-9_]+)\/status\/(\d+)$/
  );
  if (!match) {
    displayMessage("Invalid Tweet URL format.", "white");
    return null;
  }

  return tweetUrl;
}
document.getElementById("check-button").addEventListener("click", async () => {
  const tweetUrl = validateTweetUrl();
  if (!tweetUrl) return;
  // Retrieve the authenticated username from cookies
  const tweetUsername = extractUsernameFromTweetUrl(tweetUrl);
  const cookieUsername = getCookie("username");

  if (!tweetUsername) {
    console.error("Authenticated username not found in cookies.");
    return; // Stop if the cookie is missing
  }

  // Compare username from the tweet URL with the authenticated user
  if (cookieUsername !== tweetUsername) {
    console.warn("Username does not match the authenticated user.");
    return; // Stop if usernames don't match
  }

  try {
    // Trigger actions sequentially based on the action step
    switch (actionStep) {
      case 0:
        handleTweetActions("Dance");
        break;
      case 1:
        handleTweetActions("Dress");
        break;
      case 2:
        handleTweetActions("All");
        break;
      default:
        console.warn("All actions completed.");
        return; // All actions have been completed
    }

    // Increment action step for the next check
    actionStep++;
  } catch (error) {
    console.error("Error processing action:", error);
  }
});

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.set(0, 1, 4);
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
// controls.target.y = 3.5;
controls.enableDamping = true;
controls.enablePan = false;
controls.enableZoom = false;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
});
renderer.setClearColor(0x1c1c1c, 0);
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

// Tone mapping
renderer.toneMapping = THREE.NoToneMapping;
renderer.toneMappingExposure = 3;

// Shadows
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;

/**
 * Animate
 */
// Clock
const clock = new THREE.Clock();

const tick = () => {
  // Update controls
  controls.update();

  // Update mixer if it's defined
  if (mixer) mixer.update(clock.getDelta() * 1);

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
